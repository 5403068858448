import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

// Work Images
import Pawi from '../../assets/images/works-image/pawi-veteriner-yazilimi.png'
import KutuTasarimi from '../../assets/images/works-image/kutu-tasarimi-ambalaj-bicak-izi-cizim.jpg'
import Vaden from '../../assets/images/works-image/vaden-mobil-katalog-yazilimi.png'
import Randevu from '../../assets/images/works-image/randevu-siparis-mobil-uygulama.png'
import Pera from '../../assets/images/works-image/pera-pdks-yazilim-programi.png'
import GoruntuIsleme from '../../assets/images/works-image/goruntu-isleme-yazilimlari.png'
import Gorusme from '../../assets/images/works-image/online-gorusme-programlari.png'
import Celik from '../../assets/images/works-image/pro-steel-celik-uretim-yazilimi.png'



// Shape Images
import Shape2 from '../../assets/images/shape2.svg'
import Shape4 from '../../assets/images/shape4.svg'

import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    items: 4,
    loop: true,
    nav: false,
    autoplay: true,
    margin:30,
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2
        },
        768: {
            items: 2
        },
        1200: {
            items: 3
        },
        1500: {
            items: 4
        }
    }
}

const RecentWorks = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="works-area pt-80 pb-50 bg-f7fafd">
            <div className="container">
                <div className="section-title">
                    <h2>Son Çalışmalarımız</h2>
                    <div className="bar"></div>
                    <p>Her projede yeni sistemler öğrenmek, inavatif düşüncelerle şirketinizdeki gelişmelere şahit olmak oldukça keyifli bir süreç</p>
                </div>
            </div>

            {display ? <OwlCarousel 
                className="works-slides owl-carousel owl-theme"
                {...options}
            > 
                <div className="single-works">
                    <img src={Pawi} alt="veteriner yazılımları hastalık aşı takibi evcil hayvanlar" />

                    <Link to="/pawi-veteriner-asi-takip-yazilimi" className="icon">
                        <Icon.Settings />
                    </Link>

                    <div className="works-content">
                        <h3>
                            <Link to="/pawi-veteriner-asi-takip-yazilimi">
                                PawiNet - Veteriner Yazılımı
                            </Link>
                        </h3>
                        <p>Bulut sistem alt yapısıyla evcil hayvanlarınızın sağlık takibini ve aynı zamanda veteriner kliniklerinde aşı ve tedaviyi hızlıca takip edebileceğiniz Web ve Mobil uygulamamız</p>
                    </div>
                </div>
    
                <div className="single-works">
                    <img src={KutuTasarimi} alt="kutu bıçak izi online diecut template" />

                    <Link to="/bicak-izi-acilim-kutu-tasarimi" className="icon">
                        <Icon.Settings />
                    </Link>

                    <div className="works-content">
                        <h3>
                            <Link to="/bicak-izi-acilim-kutu-tasarimi">
                               Kutu Tasarımı
                            </Link>
                        </h3>
                        <p>Online Ambalaj Bıçak İzi İndirme Platformu - Ambalaj ve Grafikerlerin ihtiyaçlarına yönelik sadece en boy yükseklik girerek vektörel kutu açılımını alabilirsiniz</p>
                    </div>
                </div>
    
               

                <div className="single-works">
                    <img src={Vaden} alt="vaden mobil katalog app" />

                    <Link to="/project-details" className="icon">
                        <Icon.Settings />
                    </Link>

                    <div className="works-content">
                        <h3>
                            <Link to="/project-details">
                               Vaden Katalog 
                            </Link>
                        </h3>
                        <p>6 dilde ve tüm dünyada yayınlanmış TİM ' de ilk 1000 de yer alan Vaden firmasına ait stok katalog uygulaması</p>
                    </div>
                </div>
                <div className="single-works">
                    <img src={Celik} alt="çelik kontrüksüyon üretim akış yazılımları tekla programı" />

                    <Link to="/pro-steel-celik-konstruksiyon-uretim-takip-yazilimi" className="icon">
                        <Icon.Settings />
                    </Link>

                    <div className="works-content">
                        <h3>
                            <Link to="/pro-steel-celik-konstruksiyon-uretim-takip-yazilimi">
                               Pro Steel
                            </Link>
                        </h3>
                        <p>Çelik Kontrüksüyon üretim akış ve takip yazılımı. Tekla programı ile entegreli</p>
                    </div>
                </div>
                <div className="single-works">
                    <img src={Randevu} alt="su siparişi yazılımları" />

                    <Link to="/project-details" className="icon">
                        <Icon.Settings />
                    </Link>

                    <div className="works-content">
                        <h3>
                            <Link to="/project-details">
                                Beysu - Akademi Hastanesi ...
                            </Link>
                        </h3>
                        <p>Farklı illerde yayınlanmış su sipariş uygulamaları - Hastane randevu alma ve sonuç görüntüleme mobil uygulaması </p>
                    </div>
                </div>
    
                <div className="single-works">
                    <img src={Gorusme} alt="online görüşme randevu uygulamaları" />

                    <Link to="/project-details" className="icon">
                        <Icon.Settings />
                    </Link>
                    
                    <div className="works-content">
                        <h3>
                            <Link to="/project-details">Online Görüşme</Link>
                        </h3>
                        <p>Online görüşme platformu pazar yeri sanal pos entegrasyonu özelliklerine sahip çeşitli uygulamalarımız</p>
                    </div>
                </div>
                <div className="single-works">
                    <img src={Pera} alt="pdks yazılımları pera pdks yüz tanıma turnike parmak izi yapay zeka" />

                    <Link to="/pera-pdks-yazilimlari-otomatik-vardiya-secim" className="icon">
                        <Icon.Settings />
                    </Link>
                    
                    <div className="works-content">
                        <h3>
                            <Link to="/pera-pdks-yazilimlari-otomatik-vardiya-secim">PERA PDKS Yazılımı</Link>
                        </h3>
                        <p>Yüz tanıma sistemleri üzerine AR-GE yaptığımız Personel Takip ve Analiz Projemiz</p>
                    </div>
                </div>
                <div className="single-works">
                    <img src={GoruntuIsleme} alt="Görüntü işleme" />

                    <Link to="/pera-pdks-yazilimlari-otomatik-vardiya-secim" className="icon">
                        <Icon.Settings />
                    </Link>
                    
                    <div className="works-content">
                        <h3>
                            <Link to="/pera-pdks-yazilimlari-otomatik-vardiya-secim">Görüntü İşleme </Link>
                        </h3>
                        <p>Resimden yazıya çevirerek veri ürettiğimiz fikri mülkiyeti müşterimize ait özel projelerimiz</p>
                    </div>
                </div>
            </OwlCarousel> : ''}
            
            {/* Shape Images */}
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
        </div>
    )
}

export default RecentWorks;
