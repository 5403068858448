import React from 'react';
import * as Icon from 'react-feather';
import { Link } from "gatsby";

const Features = () => {
    return (
        <div className="boxes-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-3 col-sm-6">
						<div className="single-box">
							<div className="icon">
                                <Icon.Server />
							</div>

							<h3>
								<Link to="/feature-details">
									Proje Analiz
								</Link>
							</h3>

							<p>Parlak bir fikriniz varsa  veya firmanızda çözülmesi gereken bir süreçle karşılatıysanız projenizi analiz edip hayata geçiriyoruz  </p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-f78acb">
							<div className="icon">
                                <Icon.Code />
							</div>

							<h3>
								<Link to="/feature-details">
									Gelişmiş Teknoloji
								</Link>
							</h3>

							<p>İhtiyacınız olan yazılım projenize; son teknoloji yazılım dilleriyle, güvenli yapılar, doğru algoritmalar üretiyoruz </p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-c679e3">
							<div className="icon">
                                <Icon.Users />
							</div>

							<h3>
								<Link to="/feature-details">
									Ekip Çalışması
								</Link>
							</h3>

							<p>Geleceğe yatırım yapabilmeniz için güçlü ekibimizle projelerinizin arkasındayız. Emin adımlarla yürümeye devam edin</p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-eb6b3d">
							<div className="icon">
                                <Icon.GitBranch />
							</div>

							<h3>
								<Link to="/feature-details">
									Erişim & Güvenlik
								</Link>
							</h3>

							<p>Bilgi güvenliği ve gizlilik kurallarına uyumlu veri modelleri geliştiriyoruz Veri transferlerinde uçtan uca şifreleme yöntemleri</p>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Features;
