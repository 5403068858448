import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

const OurFeatures = () => {
    return (
        <div className="features-area pt-80 pb-50 bg-f7fafd">
            <div className="container">
                <div className="section-title">
                    <h2>Örnek Yazılım Projeleri</h2>
                    <div className="bar"></div>
                    <p>Sektörde 15 yılı aşkın hizmet süresi boyunca ve hayallerimiz doğrultusunda kurduğumuz Pixel Yazılım bünyesinde geliştirdiğimiz bir kaç örnek proje</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Maximize />
                            </div>
                            <h3>
                                <Link to="/pera-pdks-yazilimlari-otomatik-vardiya-secim">
                                    PERA PDKS Yazılımı - İnsan Kaynakları Verimlilik & Analiz
                                </Link>
                            </h3>
                            <Link to="/pera-pdks-yazilimlari-otomatik-vardiya-secim">
                                <p>  PDKS cihazından okutulan parmak izleriyle yaklaşık 600 personel çalıştıran bir marketin mağazalarında ve 300 kişilik 3 vardiyada çalışan bir fabrikada giriş, çıkış ve molalarını takip ve analiz eden projemiz</p>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Archive />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Teklif & Sipariş & Faturalandırma & Sevkiyat
                                </Link>
                            </h3>
                            <p>Bir çok işletmenin teklif, sipariş gibi süreçlerde onay mekanizmalarının tasarlanması, Logo Netsis gibi muhasebe entegrasyonları, yapılan işlemlerin mobil ve web programlarla kayıt altına alınması </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Settings />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Üretim Akış Yazılımları
                                </Link>
                            </h3>
                            <p>Fabrikaların üretim hatlarından veri toplayan, personel verimliliği, stok bakiyeleri sipariş yönetimi gibi ihtiyaçları karşılayan projeler</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.AtSign />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    e-Fatura & e-Arşiv & e-İrsaliye
                                </Link>
                            </h3>
                            <p>Gelir idaresi başkanlığınca zorunlu hale getirilen e-Dönüşüm süreçlerinin entegrasyonu ve kodlanması</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Box />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Ambalaj Sektörüne Vektörel Çizim Oluşturma
                                </Link>
                            </h3>
                            <p>Firmamız <a  aria-label="kututasarimi"   href='https://kututasarimi.com/'> www.kututasarimi.com </a> sitesinin proje ortağı olup bütün yazılım süreçlerine devam etmektedir.Sadece en boy yükseklik bilgisi girerek ambalaj açılımını çıkarmakta ve işlemektedir</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Award />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Yatırım Amaçlı Girişimci Projeler
                                </Link>
                            </h3>
                            <p>Size özel fikirleri projelendirip kodluyor ve hayata geçiriyoruz.  Evcil hayvan sağlığı takip ve yönetimi , online görüşme imkanına sahip randevu oluşturabilen yazılımlar</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-fdb428">
                                <Icon.CreditCard />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    İyzico & Banka Entegrasyonları
                                </Link>
                            </h3>
                            <p>Mobil veya Web Platformlarda kredi kartı ile ödeme alabileceğiniz sanal pos yazılımları </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-fdb428">
                                <Icon.ShoppingCart />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Sipariş Sistemleri & Uygulamalar
                                </Link>
                            </h3>
                            <p>Mobil veya Web Platformlarda müşterilerin abone olup sipariş verebilecekleri yazılımlar </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Heart />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Hastane Randevu & Sonuçlar
                                </Link>
                            </h3>
                            <p>Özel bir hastanenin mevcut sitemine entegre olarak randevu alma ve sonuç gösterme amaçlı Android ve iOS mağazalarda yayınlanmış, mobil uygulamamız  </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Airplay />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Görüntü İşleme Teknolojisi
                                </Link>
                            </h3>
                            <p>Özel bir projede kullanılmış resimden yazı oluşturma ve işleme yazılımı. Bunun yanında yüz tanıma sitemleriyle ilgili gelişme amaçlı devam eden projemiz </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurFeatures;  